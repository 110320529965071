
import { ComponentPublicInstance, computed, defineComponent, reactive, ref } from 'vue';
import UITextInput from '@/components/UI/UITextInput.vue';
import UITextDropdown from '@/components/UI/UITextSelect.vue';
import { calidadProductosState, especiesState, factorCortezaState, grupoProductosState } from '@/store/mantenedores.store';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { showToast } from '@/hooks/useToast';
import { useRouter } from 'vue-router';
import { validateForm } from '@/utils';

export default defineComponent({
  name: 'ProductCreate',
  components: {
    'ui-text-input': UITextInput,
    'ui-text-select': UITextDropdown
  },
  setup() {
    const codigoRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const codigoAdicionalRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const codigoSAPRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const codigoSISCOPRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const calidadRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const especieRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const factorRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const grupoRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const estadoRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const largoRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const largoFinalRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const largoInicialRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const largoNominalRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const largoVariableRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);

    const isValidForm = () => {
      const formRefs = [
        codigoRef,
        codigoAdicionalRef,
        codigoSAPRef,
        codigoSISCOPRef,
        calidadRef,
        especieRef,
        factorRef,
        grupoRef,
        estadoRef,
        largoRef,
        largoFinalRef,
        largoInicialRef,
        largoNominalRef,
        largoVariableRef
      ];
      let isValid =  validateForm(formRefs);
      function v(value: any) {
        if (value === null || value === undefined || value === '') isValid = false;
      }
      Object.values({ ...producto}).forEach(el => v(el));
      return isValid;
    };

    const router = useRouter();
    const producto = reactive({
      codigo: '',
      calidad: '',
      estado: '',
      codigoAdicional: '',
      codigoSAP: '',
      codigoSISCOP: '',
      largo: 0,
      largoFinal: 0,
      largoInicial: 0,
      largoNominal: 0,
      largoVariable: 0,
      grupo: '',
      factorCorteza: '',
      especie: ''
    });
    const calidadProductos = computed(() =>
      calidadProductosState.value.map((el: any) => ({
        label: el.descripcion,
        id: el._id
      }))
    );
    const especies = computed(() =>
      especiesState.value.map((el: any) => ({
        label: el.descripcion,
        id: el._id
      }))
    );
    const factorCorteza = computed(() =>
      factorCortezaState.value.map((el: any) => ({
        label: el.descripcion,
        id: el._id
      }))
    );
    const grupoProducto = computed(() =>
      grupoProductosState.value.map((el: any) => ({
        label: el.descripcion,
        id: el._id
      }))
    );
    const estadoProductos = [
      { label: 'HABILITADO', id: 'HABILITADO' },
      { label: 'DESHABILITADO', id: 'DESHABILITADO' }
    ];

    const isLoading = ref(false);
    const errors = ref<string[]>([]);
    const createProduct = async () => {
      const isValid = isValidForm();
      if (!isValid) return;
      try {
        isLoading.value = true;
        errors.value = [];
        const request = await makeRequest<any>(METHODS.POST, `producto`, true, {
          ...producto
        });

        if (request.ok) {
          console.log(request.payload);
          showToast('SUCCESS', 'Producto creado exitosamente');
          router.push({ name: 'product.list' });
        } else {
          showToast('ERROR', 'Error en proceso de creación');
          request.errors && (errors.value = request.errors.split(','));
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      producto,
      calidadProductos,
      estadoProductos,
      especies,
      factorCorteza,
      grupoProducto,
      createProduct,
      isLoading,
      errors,
      codigoRef,
      codigoAdicionalRef,
      codigoSAPRef,
      codigoSISCOPRef,
      calidadRef,
      especieRef,
      factorRef,
      grupoRef,
      estadoRef,
      largoRef,
      largoFinalRef,
      largoInicialRef,
      largoNominalRef,
      largoVariableRef
    };
  }
});
